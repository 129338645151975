<template>
  <div class="noscrollmain">
    <common-top
      title="部门关注率"
      :isShowSearch="isShowSearch"
      @search="showModal = true"
    ></common-top>

    <Modal v-model="showDetailStatus" fullscreen>
      <p slot="header" style="text-align: center">
        <span>{{ detailInfo["id"] }}</span>
      </p>

      <pie-chart
        v-if="showDetailStatus"
        :id="`deptUserFollow`"
        :chartSytle="`width:90vw; height: 300px`"
        :option="deptUserOption"
      ></pie-chart>
      <div style="text-align: center; line-height: 30px">
        <div class="mainTitleTap1">总人数:{{ detailInfo["总人数"] }}</div>
        <div class="mainTitleTap2">已关注:{{ detailInfo["已激活"] }}</div>
        <div class="mainTitleTap3" v-if="detailInfo['已禁用']">
          已禁用:{{ detailInfo["已禁用"] }}
        </div>
        <div class="mainTitleTap4" v-if="detailInfo['未激活']">
          未激活: {{ detailInfo["未激活"] }}
        </div>
        <div class="mainTitleTap5" v-if="detailInfo['退出企业']">
          退出企业:{{ detailInfo["退出企业"] }}
        </div>
      </div>

      <div slot="footer">
        <Button size="large" long @click="showDetailStatus = false"
          >确定</Button
        >
      </div>
    </Modal>

    <dept-choose-com
      :showModal="showModal"
      @chooseDept="chooseDept"
      :isShowDept="true"
      @cancel="showModal = false"
      :chooseDate="false"
    ></dept-choose-com>
    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>
    <loading-cmp v-if="showLoad"></loading-cmp>

    <div v-show="showList && isUserPm">
      <list-com
        @chooseListItem="chooseListItem"
        :columns="columns"
        :title="title"
        :showList="dataList"
        :showLoad="showLoad"
      ></list-com>
    </div>

    <no-pass-com v-show="!isUserPm"> </no-pass-com>
  </div>
</template>

<script>
// @ is an alias to /src
import _ from "lodash";

import { statsPost, getUserPm } from "../../api/httpApi";
import commonTop from "../../components/commonTop.vue";
import pieChart from "../../components/pieChart.vue";
import loadingCmp from "../../components/loadingCmp.vue";
import diaLogliu from "../../components/dialogLiu.vue";
import listCom from "../../components/listCom.vue";
import deptChooseCom from "../../components/deptLevelChooseCom.vue";
import noPassCom from "../../components/noPassCom.vue";

export default {
  name: "deptUserFollow",
  components: {
    pieChart,
    loadingCmp,
    commonTop,
    diaLogliu,
    listCom,
    deptChooseCom,
    noPassCom,
  },

  data() {
    return {
      isShowSearch: false, // 是否显示搜索框
      isUserPm: true, // 是否有权限
      filterNameArr: [],

      showModal: false, // 显示部门弹框
      deptIdArr: [], // 选择部门
      deptLevel: 3, // 部门层级
      level: 2,

      showDetailStatus: false,
      detailInfo: {}, // 单位详情
      title: "单位",
      columns: ["总人数", "关注人数", "关注率"],
      showList: true,
      deptData: [],
      dataList: [],
      showLoad: false,
      deptType: "单位",
      deptUserOption: {},
      defaultOption: {
        legend: {
          orient: "vertical",
          x: "right",
          data: ["未关注", "已关注"],
        },
        // color: ["#507559", "#D8D8D8"],
        title: {
          text: "",
          left: "center",
          top: "42%",
          textStyle: {
            // color: "#507559",
            fontSize: 13,
            align: "center",
          },
        },
        graphic: {
          type: "text",
          left: "center",
          top: "40%",
          style: {
            // text: "关注率",
            textAlign: "center",
            fill: "#333",
            fontSize: 20,
            fontWeight: 700,
          },
        },
        series: [
          {
            name: "关注比例",
            position: "center",

            type: "pie",
            radius: ["50%", "70%"],
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
              },
              emphasis: {
                label: {
                  show: false,
                  position: "center",
                  textStyle: {
                    fontSize: "30",
                    fontWeight: "bold",
                  },
                },
              },
            },
            data: [],
          },
        ],
      },
      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项
    };
  },
  methods: {
    chooseDept(emitData) {
      this.level = emitData.level;
      this.deptIdArr = emitData.deptIdArr;
      this.showModal = false;
      this.getDeptStatus();
    },
    async getDeptStatus() {
      try {
        this.showLoad = true;
        this.showTaskList = false;
        let _query = {
          type: "status",
          level: this.level,
          parentDept: this.deptIdArr,
        };

        const ret = await statsPost("/dept/tj_wbl", _query);
        if (ret.errMsg) {
          this.dailogshow = true;
          this.dailogoptions.content = ret.errMsg;
          this.dailogoptions.textColor = "white";
          setTimeout(() => {
            this.dailogshow = false;
          }, 2000);
        }
        this.showLoad = false;
        let deptData = ret.ret ? ret.ret : [];
        // this.filterNameArr = ret.filterNameArr;

        this.dealData(deptData);
      } catch (error) {
        this.dailogshow = true;
        this.dailogoptions.content = "请稍后重试";
        this.dailogoptions.textColor = "white";
        setTimeout(() => {
          this.dailogshow = false;
        }, 2000);
        console.log("----", error);
      }
    },
    // 处理数据
    dealData(data) {
      // 单位: "职教测试"
      // "单位", "总人数", "关注人数", "关注率"
      let dataList = [];

      let fillStatus = false;
      // 增加过滤数据 非领导班子 站段4级管理员
      if (!_.isEmpty(this.filterNameArr)) {
        fillStatus = true;
      }

      const userDataALl = {
        id: "汇总",
        单位: "汇总",

        关注人数: 0,

        已激活: 0,

        xType: 1,
        关注率: 0,
        单位: "",
        已激活: 0,
        已禁用: 0,
        总人数: 0,
        未激活: 0,
        退出企业: 0,
      };

      for (const iterator of data) {
        if (iterator["单位"]) {
          let followRate = 0;
          if (iterator["总人数"]) {
            followRate = (iterator["已激活"] / iterator["总人数"]) * 100;
          }

          if (fillStatus) {
            if (this.filterNameArr.indexOf(iterator["单位"]) == -1) {
              continue;
            }
          }
          dataList.push(
            Object.assign(iterator, {
              id: iterator["单位"],
              单位: iterator["单位"],
              总人数: iterator["总人数"],
              关注人数: iterator["已激活"],
              关注率: `${followRate.toFixed(2)}%`,
              followRate,
            })
          );

          for (const itemName in iterator) {
            if (!userDataALl[itemName]) {
              userDataALl[itemName] = 0;
            }
            userDataALl[itemName] += iterator[itemName];
          }
        }
      }
      dataList = _.reverse(_.sortBy(dataList, ["followRate", "总人数"]));
      this.dataList = dataList;
      if (this.dataList.length == 0) {
        return;
      }

      userDataALl["关注人数"] = userDataALl["已激活"];
      userDataALl["单位"] = "汇总";
      userDataALl["id"] = "汇总";
      if (userDataALl["总人数"]) {
        const followRateAll =
          (userDataALl["已激活"] / userDataALl["总人数"]) * 100;

        userDataALl["关注率"] = `${followRateAll.toFixed(2)}%`;
      }

      this.dataList.push(userDataALl);
    },

    chooseListItem(item) {
      // 显示详情
      this.deptUserOption = null;
      this.detailInfo = item;
      this.showDetailStatus = true;
      let deptUserOption = JSON.parse(JSON.stringify(this.defaultOption));
      // 已激活: 2
      // 已禁用: 0
      // 总人数: 2
      // 未激活: 0
      // 退出企业: 0

      // console.log(
      //   "----",
      //   this.detailInfo["关注率"],
      //   parseFloat(this.detailInfo["关注率"]),
      //   parseFloat(this.detailInfo["关注率"]) / 100,
      //   `${1 - parseFloat(this.detailInfo["关注率"]) / 100}`
      // );
      const followRate = this.detailInfo["关注率"].replace(/\%/g, "");

      deptUserOption["series"][0]["data"].push({
        value: `${1 - parseFloat(followRate) / 100}`,
        name: "未关注",
        itemStyle: {
          color: "#eee",
        },
      });
      deptUserOption["series"][0]["data"].push({
        value: `${parseFloat(followRate) / 100}`,
        name: "已关注",
      });

      deptUserOption["title"]["text"] =
        this.detailInfo["关注率"].indexOf("100") > -1
          ? "100%"
          : this.detailInfo["关注率"];

      this.deptUserOption = deptUserOption;
    },
    chooseItem(data) {
      this.showTaskList = false;
      this.deptData = data.data.retData;
    },
  },
  async created() {
    const userPm = getUserPm();

    if (userPm) {
      this.level = userPm.datalevel;
      this.deptIdArr = JSON.parse(localStorage.getItem("pmDeptIdArr"));

      if (userPm.pmValue && userPm.statsPmStatus) {
        this.isShowSearch = true;
      }

      this.getDeptStatus();
    } else {
      this.isUserPm = false;
    }
  },
};
</script>

<style scoped></style>
